import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faCheckCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from 'reactstrap';
import CampaignFlowXs from '../../../assets/images/chrismongemeinde2025-ablaufgrafik-mobil-rgb.svg'
import CampaignFlowLg from '../../../assets/images/chrismongemeinde2025-ablaufgrafik-desktop-rgb.svg'
import moment from 'moment';

const campaignFlow = (props) => {

    const shortenDate = (from, until) => {
        if (until) {
            return moment(from).format('DD.MM.') + ' - ' + moment(until).format('DD.MM.');
        }
        return moment(from).format('DD.MM.');
    };

    return (
        <picture>
            <source srcset={CampaignFlowLg} media="(min-width: 992px)"/>
            <img src={CampaignFlowXs} alt="Grafik, die den Ablauf des Wettbewerbs beschreibt.
            Bis zum 1.4. können Sie sich bewerben; vom 11.3. bis zum 1.4 können Sie sich für das Finale qualifizieren, warten Sie also nicht zu lange mit der Bewerbung!
            Vom 1.4. bis zum 3.4. läuft dann das Finale, wo Sie erneut für Ihr Projekt stimmen sammeln müssen."
            className={"align-self-center w-100"}/>
        </picture>
    )
};

export default campaignFlow;

