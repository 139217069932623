import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';
import {Nav, NavLink} from 'reactstrap';
import {NavLink as RRNavLink} from 'react-router-dom';
import MenuItemPlaceholder from "../../UI/Placeholder/MenuItemPlaceholder/MenuItemPlaceholder";
import Cookies from "js-cookie";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/free-solid-svg-icons'

const footerMenu = (props) => {

    const onClickHandler = (event) => {
        event.preventDefault()
        Cookies.set('bigfoot_cookie-consent', false, {expires: 180})
        window.location.reload(false);
    };

    let secondaryNavigation = (
        <React.Fragment>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
            <li style={{width: '140px', padding: '8px'}}><MenuItemPlaceholder/></li>
        </React.Fragment>
    );

    if (props.secondaryNavigation) {
        secondaryNavigation = props.secondaryNavigation.map((menuItem, index) => {
            return <NavigationItem key={index} type="footer">
                {
                    /https?:\/\/.*/.test(menuItem.linkTarget)
                        ? <a className={`anl_footer--menu-lnk-${index + 1} nav-link`} href={menuItem.linkTarget}
                             style={{color: "white", paddingTop: '24px', paddingBottom: "24px"}}>
                            {menuItem.text} <FontAwesomeIcon icon={faExternalLink}/>
                        </a>
                        : <NavLink className={`anl_footer--menu-lnk-${index + 1}`}
                                   to={menuItem.linkTarget === '/' ? '/' : menuItem.linkTarget + '/'} tag={RRNavLink}
                                   exact
                                   style={{
                                       color: "white",
                                   }}>{menuItem.text}</NavLink>
                }
            </NavigationItem>
        })
    }

    return (
        <div>
            <Nav className="ms-auto flex-column flex-lg-row justify-content-end">
                {secondaryNavigation}
                <NavigationItem type="footer"
                                style={{color: "white", paddingTop: '24px', paddingBottom: "24px"}}>
                    <NavLink onClick={onClickHandler} href="#"
                             style={{
                                 color: "white",
                             }}>Datenschutzpräferenzen</NavLink>
                </NavigationItem>
            </Nav>
        </div>
    )
};

export default footerMenu;
