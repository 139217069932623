import React, {useEffect, useState} from 'react';
import Aux from '../../hoc/Aux/Aux';
import axios from '../../axios-base';
import {checkValidity} from '../../shared/utility';
import Input from '../../components/UI/Input/FormInput';
import {Button, Col, FormText} from 'reactstrap';
import Cookies from "js-cookie";
import {useHistory, useParams} from 'react-router-dom';

function SMSVote(props) {
    const [votingButton, setVotingButton] = useState('Jetzt per SMS abstimmen!');
    const [votingReady, setVotingReady] = useState(false);
    const [SMSCodeConfig, setSMSCodeConfig] = useState({
        elementType: 'input',
        elementConfig: {
            type: 'text',
            inputMode: "text",
            placeholder: 'Beispiel: PKX8QKZ8',
            label: 'Ihr SMS-Code zur Stimmabgabe',
            columns: 12,
            disabled: false
        },
        value: '',
        validation: {
            required: {value: true, errorMessage: "Bitte geben Sie einen gültige SMS Code an"},
            isSMSCode: {value: true, errorMessage: "Bitte geben Sie einen gültige SMS Code an"},
            maxLength: {value: 8, errorMessage: "Bitte geben Sie einen gültige SMS Code an."},
            minLength: {value: 8, errorMessage: "Bitte geben Sie einen gültige SMS Code an"}
        },
        valid: false,
        errorMessage: 'Bitte geben Sie eine gültige SMS Code an',
        touched: false,
        ifAuth: false
    });
    const [voteButtonVisibility, setVoteButtonVisibility] = useState(true);
    const [captchaContainerVisibility, setCaptchaContainerVisibility] = useState(true);

    let history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        if (props.projectCategory) {
            Cookies.set('bigfoot_redirect-category', props.projectCategory, {expires: 180});
        }
    }, [props.projectCategory]);


    const navigateToPage = () => {
        history.push('/code-anfordern/');
    };

    const onChangeHandler = event => {
        const userInput = event.target.value.toUpperCase();

        const updatedSMSCode = {
            ...SMSCodeConfig
        };

        updatedSMSCode.value = userInput;
        let validity = checkValidity(updatedSMSCode.value, updatedSMSCode.validation);
        updatedSMSCode.valid = validity.isValid;
        updatedSMSCode.errorMessage = validity.errorMessage ?? '';
        updatedSMSCode.touched = true;

        let updatedVotingReady = updatedSMSCode.valid;
        setSMSCodeConfig(updatedSMSCode);
        setVotingReady(updatedVotingReady);

        if (updatedVotingReady) {
            setVotingButton("Jetzt per SMS abstimmen!")
        }
    };

    const onVoteHandler = () => {
        smsVote();
    };

    const smsVote = () => {
        let fetchPromise;

        if (process.env.REACT_APP_AWS_WAF_INTEGRATION_USE === 'true') {
            fetchPromise = window.AwsWafIntegration.getToken().catch(err => {
                handleUnsuccessfulSMSRequest(err);
            }).then(token => {
                return axios.patch('/sms', {
                        'code': SMSCodeConfig.value,
                        'projectId': id
                    },
                    {
                        headers: {
                            'x-aws-waf-token': token,
                        }
                    }
                );
            });
        } else {
            fetchPromise = axios.patch('/sms', {
                'code': SMSCodeConfig.value,
                'projectId': id
            });
        }

        fetchPromise.then(response => {
             if (response.status === 200) {
                let updatedSMSCode = {...SMSCodeConfig};
                updatedSMSCode.elementConfig.disabled = true;
                setVotingReady(false);
                setVotingButton('Sie haben bereits abgestimmt');
                setSMSCodeConfig(updatedSMSCode);

                Cookies.set('survey-answer-id', response.data.surveyAnswerId, {expires: 7});
                Cookies.remove('bigfoot_code-requested');
                history.push({
                    pathname: '/stimme-bestaetigt',
                    state: response.data.surveyAnswerId
                })
            } else {
                handleUnsuccessfulSMSRequest(response);
            }
        }).catch(err => {
            if (err.response.status === 405) {
                //hide sms request button
                setVoteButtonVisibility(false);
                displayCaptchaPuzzle();
            } else {
                handleUnsuccessfulSMSRequest(err);
            }

        })
    }

    const displayCaptchaPuzzle = () => {
        const container = document.querySelector("#captcha-container");
        window.AwsWafCaptcha.renderCaptcha(container, {
            apiKey: process.env.REACT_APP_AWS_WAF_CAPTCHA_KEY,
            onSuccess: onCaptchaVerified,
            dynamicWidth: true,
            defaultLocale: "de-DE"
        });
    }

    const onCaptchaVerified = () => {
        //Called on Successful Captcha
        //Hide captcha success message to remove confusion in voting process
        // Display voting button as animation progress and redirect are shown within it
        setVoteButtonVisibility(true);
        setCaptchaContainerVisibility(false);
        onVoteHandler();
    }

    const handleUnsuccessfulSMSRequest = (responseOrError) => {
        // Check if it's a fetch response or an error object
        if (responseOrError instanceof Response) {
            // Handle fetch Response object
            responseOrError.text().then((responseText) => {
                setVotingReady(false);
                setVotingButton('Nicht erfolgreich');
            }).catch(err => {
                setVotingReady(false);
                setVotingButton('Nicht erfolgreich');
            });
        } else {
            setVotingReady(false);
            setVotingButton('Nicht erfolgreich');
        }
    }

    let form = (
        <Aux>
            <Input
                wrapperCSSClass="px-0 mb-0"
                type={SMSCodeConfig.elementType}
                name="SMSCode"
                id="SMSCode"
                placeholder={SMSCodeConfig.elementConfig.placeholder}
                elementType={SMSCodeConfig.elementType}
                elementConfig={SMSCodeConfig.elementConfig}
                value={SMSCodeConfig.value}
                changed={(event) => onChangeHandler(event)}
                shouldValidate={SMSCodeConfig.validation}
                touched={SMSCodeConfig.touched}
                errorMessage={SMSCodeConfig.errorMessage}
                invalid={!SMSCodeConfig.valid}
                formText={SMSCodeConfig.formText}/>
            <FormText className="mb-3">Bitte Ihren 8-stelligen SMS-Code eingeben. <span style={{cursor: 'pointer'}}
                                                                                        className="text-primary anl_sms--redirect-request"
                                                                                        role="button"
                                                                                        onClick={() => navigateToPage()}>Noch keinen Code?</span>
            </FormText>
        </Aux>
    );

    return (
        <Col xs="12" sm="6" lg="12">
            {form}
            <div className={`w-100 ${captchaContainerVisibility ? "" : "d-none"}`} id="captcha-container"/>
            {voteButtonVisibility && (
                <Button
                    className={votingReady ? 'anl_sms--vote' : null}
                    block
                    disabled={!votingReady}
                    color="primary"
                    onClick={() => onVoteHandler()}
                >
                    {votingButton}
                </Button>
            )}
        </Col>
    );
}

export default SMSVote;
